<template>
  <Html>
    <Body id="app">
      <main>
        <div class="content">
          <a
            :href="`https://${useRuntimeConfig().public.productionDomain}`"
            class="logo-container"
          >
            <MainLogo class="main-logo" />
          </a>

          <h1 v-if="props.error.statusCode !== 404">
            {{ props.error.statusCode }}
          </h1>
          <h2 v-html="props.error.message" />
        </div>

        <div
          v-if="hasStack"
          class="stack-wrapper"
          v-html="props.error.stack"
        />
      </main>
    </Body>
  </Html>
</template>

<script setup>
const props = defineProps({
  error: {
    type: Object,
    required: true,
  },
});

const hasStack = computed(() => props.error.stack && import.meta.dev);

useSeoMeta({
  title: `${props.error.statusCode} - ${striptags?.(props.error.message)} | Attendu`,
});

if (props.error.statusCode === 404 && useRequestURL().host.includes('.attendu.cz')) { // redirects from attendu2
  navigateTo(useRequestURL().origin.replace('.attendu.cz', '.attendu.com'), { external: true });
}
</script>

<style scoped>
main {
  min-height: 100vh;
  min-height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  font-family: Inter, sans-serif;
}

.content {
  max-width: 30rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  width: 100%;
  text-align: center;
}

h1 {
  font-size: clamp(1.688rem, 1.35rem + 1.13vw, 2.25rem);
  letter-spacing: -0.02em;
  line-height: 1.2;
  font-weight: bold;
  margin-bottom: 2rem;
  display: block;
}

h2 {
  font-size: clamp(1.25rem, 1.1rem + 0.5vw, 1.5rem);
  letter-spacing: -0.01em;
  line-height: 1.35;
  color: #24292e;
  margin-bottom: 2rem;
  display: block;
}

.logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.main-logo {
  width: 10rem;
  height: auto;
}

.stack-wrapper {
  width: 90%;
}

.stack-wrapper :deep(pre) {
  background: rgb(0 0 0 / 0.05);
  border-radius: 0.25rem;
  padding: 1rem;
  width: 100%;
  overflow-y: auto;
  height: auto;
  font-size: 0.75rem;
  color: #444d56;
}
</style>
