export const useAuthStore = defineStore('auth', {
  state: () => {
    return {
      user: null,
      token: null,
      refresh: null,
    };
  },

  actions: {
    setAuth(payload) {
      if (payload) {
        const token = `Bearer ${payload.token}`;
        this.token = token;
        this.refresh = payload.refresh;
        this.user = payload.user;

        const maxAge = useDayjs.duration({ days: 14 }).asSeconds();
        useCookie('token', { maxAge, secure: true }).value = token;
        useCookie('refresh', { maxAge, secure: true }).value = payload.refresh;
      } else {
        this.token = null;
        this.refresh = null;
        this.user = null;
        useCookie('token').value = null;
        useCookie('refresh').value = null;
      }
    },

    async login({ email, password }) {
      const response = await useApi.post('/public/users/login', {
        body: {
          email,
          password,
        },
      }).catch((error) => {
        console.error(error);
        if (error.response?.status === 403) {
          useToast.error(useT('abc322'));
        } else if (error.response?.status === 498) {
          useToast.error(useT('abc323'));
        } else if (!error.response?.status) {
          useToast.error(useT('xyz107'));
        } else {
          useToast.error(useT('xyz324'));
        }
      });

      if (response) {
        this.setAuth(response);

        return response.user;
      }

      return false;
    },

    async logout() {
      const refreshToken = this.refresh;

      this.setAuth(null);
      window?.sessionStorage?.removeItem?.('redirectedFromPath');

      navigateTo('/login');

      if (refreshToken) {
        await useApi.post('/users/logout', {
          body: {
            refreshToken,
          },
        }).then(() => {
          console.log('Logged out');
        }).catch((error) => {
          console.error(error);
        });
      }
    },

    async fetchUser() {
      const response = await useApi.get('/users/me').catch(() => false);

      if (response) {
        this.user = response;

        return this.user;
      }

      return false;
    },

    async register({ token, email, fullName, password, locale }) {
      const response = await useApi.post('/public/users/register', {
        body: {
          token,
          email,
          fullName,
          password,
          locale,
        },
      }).catch(() => {
        useToast.error(useT('abc321'));
      });

      return response;
    },

    async forgottenPassword({ email, lang }) {
      const response = await useApi.post('/public/users/forgotten-password', {
        body: {
          email,
          lang,
        },
      }).catch(() => {
        useToast.error(useT('abc320'));
      });

      return response;
    },

    async newPassword({ email, password, token }) {
      const response = await useApi.post('/public/users/new-password', {
        body: {
          email,
          password,
          token,
        },
      }).catch(() => {
        useToast.error(useT('abc319'));
      });

      return response;
    },
  },

  getters: {
    isLoggedIn(state) {
      return !!state.user;
    },
  },
});
