<template>
  <svg
    width="176"
    height="48"
    viewBox="0 0 176 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M103.058 14v20.3152h3.434V22.7584c0-.8551-.081-1.3475-.107-1.477.241.3627.59.7255.912 1.0364l13.306 12.5416V14.5701h-3.434V26.101c0 .6343.059 1.0833.09 1.3151v.0002c.01.0806.017.135.017.1617-.241-.3368-.59-.7255-.912-1.0364L103.058 14ZM39.21 34.2896h-3.4338V17.5243h-5.9287v-2.954h15.2376v2.954H39.21v16.7653ZM13.2682 14.6221l1.6901 3.1872L6.481 34.8337l-5.4458-7.2814 1.529-3.1095 3.4071 4.4051c1.2483-2.4465 2.294-4.4753 3.3201-6.4662l.0002-.0003c1.2453-2.416 2.4618-4.7762 3.9766-7.7593Zm1.3417 9.1982L16.3 20.4258l7.431 13.8631h-3.6484l-2.0389-3.9646h-6.6799l1.4487-2.8504h3.7021l-1.9047-3.6536Zm45.2834 10.4693h3.4338V17.5243h5.8751v-2.954H53.9646v2.954h5.9287v16.7653Zm32.353 0H79.5841V14.5703h12.2598v2.954h-8.826v5.0788h8.37v2.954h-8.37v5.7785h9.2284v2.954Zm47.6177-19.7193c5.553 0 10.033 4.3533 10.033 9.6912 0 5.5452-4.641 10.0281-10.382 10.0281h-7.19V14.5703h7.539Zm-4.078 16.7653h3.729c3.863 0 6.948-3.1613 6.948-7.0741 0-3.7314-2.951-6.7372-6.599-6.7372h-4.078v13.8113Zm31.683 3.3945c5.928 0 8.531-2.9799 8.531-6.9186V14.5703h-3.488v13.2412c0 2.2025-1.529 3.9387-5.07 3.9387-3.514 0-5.07-1.7362-5.07-3.9387V14.5703h-3.434v13.2412c0 3.9646 2.629 6.9186 8.531 6.9186Z"
      fill="#3ACA51"
    />
  </svg>
</template>
