import { ElMessage } from 'element-plus';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:chunkError', ({ error }) => {
    console.warn('[chunk-error.js] chunk load error', error);

    ElMessage({
      showClose: true,
      dangerouslyUseHTMLString: true,
      grouping: true,
      customClass: '[&_.el-badge]:hidden',
      message: useT('abc1054'),
    });
  });
});
