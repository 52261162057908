import { defineNuxtPlugin } from '#app/nuxt'
import { LazyBuilderEmailButtonBasic, LazyBuilderEmailButtonGroup, LazyBuilderEmailDividerBasic, LazyBuilderEmailGuestDetailsAppAccess, LazyBuilderEmailGuestDetailsBasic, LazyBuilderEmailImageBasic, LazyBuilderEmailListBasic, LazyBuilderEmailListWithImage, LazyBuilderEmailMapBasic, LazyBuilderEmailSalutationBasic, LazyBuilderEmailTextBasic, LazyBuilderPageAgendaBasic, LazyBuilderPageBarBasic, LazyBuilderPageBarNavigation, LazyBuilderPageCollapsibleBasic, LazyBuilderPageDividerBasic, LazyBuilderPageGridBasic, LazyBuilderPageHeaderBasic, LazyBuilderPageHeaderBottomBox, LazyBuilderPageHeaderTopBlock, LazyBuilderPageImageBasic, LazyBuilderPageImageNarrow, LazyBuilderPageMapBasic, LazyBuilderPageSpeakersBasic, LazyBuilderPageSponsorsBasic, LazyBuilderPageTextBasic, LazyBuilderPageTextNarrow, LazyBuilderPageTextTwoColumns, LazyBuilderPageTextWithImage, LazyBuilderPageFormBasic, LazyPageAgendaBasic, LazyPageBarBasic, LazyPageBarNavigation, LazyPageCollapsibleBasic, LazyPageContainer, LazyPageDividerBasic, LazyPageFormBasic, LazyPageGridBasic, LazyPageHeaderBasic, LazyPageHeaderBottomBox, LazyPageHeaderTopBlock, LazyPageImageBasic, LazyPageImageNarrow, LazyPageMapBasic, LazyPageSpeakersBasic, LazyPageSponsorsBasic, LazyPageTextBasic, LazyPageTextNarrow, LazyPageTextTwoColumns, LazyPageTextWithImage } from '#components'
const lazyGlobalComponents = [
  ["BuilderEmailButtonBasic", LazyBuilderEmailButtonBasic],
["BuilderEmailButtonGroup", LazyBuilderEmailButtonGroup],
["BuilderEmailDividerBasic", LazyBuilderEmailDividerBasic],
["BuilderEmailGuestDetailsAppAccess", LazyBuilderEmailGuestDetailsAppAccess],
["BuilderEmailGuestDetailsBasic", LazyBuilderEmailGuestDetailsBasic],
["BuilderEmailImageBasic", LazyBuilderEmailImageBasic],
["BuilderEmailListBasic", LazyBuilderEmailListBasic],
["BuilderEmailListWithImage", LazyBuilderEmailListWithImage],
["BuilderEmailMapBasic", LazyBuilderEmailMapBasic],
["BuilderEmailSalutationBasic", LazyBuilderEmailSalutationBasic],
["BuilderEmailTextBasic", LazyBuilderEmailTextBasic],
["BuilderPageAgendaBasic", LazyBuilderPageAgendaBasic],
["BuilderPageBarBasic", LazyBuilderPageBarBasic],
["BuilderPageBarNavigation", LazyBuilderPageBarNavigation],
["BuilderPageCollapsibleBasic", LazyBuilderPageCollapsibleBasic],
["BuilderPageDividerBasic", LazyBuilderPageDividerBasic],
["BuilderPageGridBasic", LazyBuilderPageGridBasic],
["BuilderPageHeaderBasic", LazyBuilderPageHeaderBasic],
["BuilderPageHeaderBottomBox", LazyBuilderPageHeaderBottomBox],
["BuilderPageHeaderTopBlock", LazyBuilderPageHeaderTopBlock],
["BuilderPageImageBasic", LazyBuilderPageImageBasic],
["BuilderPageImageNarrow", LazyBuilderPageImageNarrow],
["BuilderPageMapBasic", LazyBuilderPageMapBasic],
["BuilderPageSpeakersBasic", LazyBuilderPageSpeakersBasic],
["BuilderPageSponsorsBasic", LazyBuilderPageSponsorsBasic],
["BuilderPageTextBasic", LazyBuilderPageTextBasic],
["BuilderPageTextNarrow", LazyBuilderPageTextNarrow],
["BuilderPageTextTwoColumns", LazyBuilderPageTextTwoColumns],
["BuilderPageTextWithImage", LazyBuilderPageTextWithImage],
["BuilderPageFormBasic", LazyBuilderPageFormBasic],
["PageAgendaBasic", LazyPageAgendaBasic],
["PageBarBasic", LazyPageBarBasic],
["PageBarNavigation", LazyPageBarNavigation],
["PageCollapsibleBasic", LazyPageCollapsibleBasic],
["PageContainer", LazyPageContainer],
["PageDividerBasic", LazyPageDividerBasic],
["PageFormBasic", LazyPageFormBasic],
["PageGridBasic", LazyPageGridBasic],
["PageHeaderBasic", LazyPageHeaderBasic],
["PageHeaderBottomBox", LazyPageHeaderBottomBox],
["PageHeaderTopBlock", LazyPageHeaderTopBlock],
["PageImageBasic", LazyPageImageBasic],
["PageImageNarrow", LazyPageImageNarrow],
["PageMapBasic", LazyPageMapBasic],
["PageSpeakersBasic", LazyPageSpeakersBasic],
["PageSponsorsBasic", LazyPageSponsorsBasic],
["PageTextBasic", LazyPageTextBasic],
["PageTextNarrow", LazyPageTextNarrow],
["PageTextTwoColumns", LazyPageTextTwoColumns],
["PageTextWithImage", LazyPageTextWithImage],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
