import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
  const sentryDsn = useRuntimeConfig().public.sentryDsn;

  if (!sentryDsn) {
    return;
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    // autoSessionTracking: true,
    dsn: sentryDsn,
    // debug: true,
    integrations: [
      Sentry.browserTracingIntegration({
        router: nuxtApp.$router,
        // tracePropagationTargets: [config.public.SENTRY_TRACE_PROPAGATION_TARGET],
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false,
        useCompression: false,
        // networkDetailAllowUrls: [
        //   useRequestURL().origin,
        //   /^\/api\//,
        // ],
      }),
    ],
    trackComponents: true,
    hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.3,
    ignoreErrors: ['ResizeObserver loop limit exceeded', 'Connection closed', 'Connection to server unavailable'],

    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
  });

  return {
    provide: {
      sentry: Sentry,
      replay: Sentry.getClient().getIntegrationByName('Replay'),
    },
  };
});
