export default defineNuxtRouteMiddleware(async(to) => {
  if (import.meta.client) {
    let isLoggedIn = useAuthStore().isLoggedIn;

    if (useCookie('token').value && !isLoggedIn) {
      await useAuthStore().fetchUser();
      isLoggedIn = useAuthStore().isLoggedIn;
    }

    if (useError().value) { // for error page
      return abortNavigation();
    }

    if (to.meta.public && isLoggedIn && to.meta?.layout !== 'page' && to.meta?.layout !== 'guest-app' && to.meta?.layout !== 'quote') {
      return navigateTo('/');
    }
    if (!to.meta.public && !isLoggedIn) {
      window?.sessionStorage?.setItem?.('redirectedFromPath', to.path);
      return navigateTo('/login');
    }
  }
});
