
// @ts-nocheck
import locale__vercel_path0_locales_cs_json5 from "../locales/cs.json5";
import locale__vercel_path0_locales_en_json5 from "../locales/en.json5";
import locale__vercel_path0_locales_sk_json5 from "../locales/sk.json5";
import locale__vercel_path0_locales_pl_json5 from "../locales/pl.json5";
import locale__vercel_path0_locales_hu_json5 from "../locales/hu.json5";
import locale__vercel_path0_locales_de_json5 from "../locales/de.json5";
import locale__vercel_path0_locales_es_json5 from "../locales/es.json5";
import locale__vercel_path0_locales_fr_json5 from "../locales/fr.json5";


export const localeCodes =  [
  "cs",
  "en",
  "sk",
  "pl",
  "hu",
  "de",
  "es",
  "fr"
]

export const localeLoaders = {
  "cs": [{ key: "../locales/cs.json5", load: () => Promise.resolve(locale__vercel_path0_locales_cs_json5), cache: true }],
  "en": [{ key: "../locales/en.json5", load: () => Promise.resolve(locale__vercel_path0_locales_en_json5), cache: true }],
  "sk": [{ key: "../locales/sk.json5", load: () => Promise.resolve(locale__vercel_path0_locales_sk_json5), cache: true }],
  "pl": [{ key: "../locales/pl.json5", load: () => Promise.resolve(locale__vercel_path0_locales_pl_json5), cache: true }],
  "hu": [{ key: "../locales/hu.json5", load: () => Promise.resolve(locale__vercel_path0_locales_hu_json5), cache: true }],
  "de": [{ key: "../locales/de.json5", load: () => Promise.resolve(locale__vercel_path0_locales_de_json5), cache: true }],
  "es": [{ key: "../locales/es.json5", load: () => Promise.resolve(locale__vercel_path0_locales_es_json5), cache: true }],
  "fr": [{ key: "../locales/fr.json5", load: () => Promise.resolve(locale__vercel_path0_locales_fr_json5), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.js?hash=c231e734&config=1" /* webpackChunkName: "i18n_config_c231e734" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "cs",
      "iso": "cs",
      "name": "Česky",
      "icon": "custom/flag-cz",
      "inInterface": true,
      "files": [
        "locales/cs.json5"
      ]
    },
    {
      "code": "en",
      "iso": "en",
      "name": "English",
      "icon": "custom/flag-en",
      "inInterface": true,
      "files": [
        "locales/en.json5"
      ]
    },
    {
      "code": "sk",
      "iso": "sk",
      "name": "Slovenčina",
      "icon": "custom/flag-sk",
      "files": [
        "locales/sk.json5"
      ]
    },
    {
      "code": "pl",
      "iso": "pl",
      "name": "Polski",
      "icon": "custom/flag-pl",
      "files": [
        "locales/pl.json5"
      ]
    },
    {
      "code": "hu",
      "iso": "hu",
      "name": "Magyar",
      "icon": "custom/flag-hun",
      "files": [
        "locales/hu.json5"
      ]
    },
    {
      "code": "de",
      "iso": "de",
      "name": "Deutsch",
      "icon": "custom/flag-de",
      "files": [
        "locales/de.json5"
      ]
    },
    {
      "code": "es",
      "iso": "es",
      "name": "Español",
      "icon": "custom/flag-es",
      "files": [
        "locales/es.json5"
      ]
    },
    {
      "code": "fr",
      "iso": "fr",
      "name": "Français",
      "icon": "custom/flag-fr",
      "files": [
        "locales/fr.json5"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": false,
  "langDir": "locales/",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": false
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": true,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "cs",
    "iso": "cs",
    "name": "Česky",
    "icon": "custom/flag-cz",
    "inInterface": true,
    "files": [
      {
        "path": "locales/cs.json5"
      }
    ]
  },
  {
    "code": "en",
    "iso": "en",
    "name": "English",
    "icon": "custom/flag-en",
    "inInterface": true,
    "files": [
      {
        "path": "locales/en.json5"
      }
    ]
  },
  {
    "code": "sk",
    "iso": "sk",
    "name": "Slovenčina",
    "icon": "custom/flag-sk",
    "files": [
      {
        "path": "locales/sk.json5"
      }
    ]
  },
  {
    "code": "pl",
    "iso": "pl",
    "name": "Polski",
    "icon": "custom/flag-pl",
    "files": [
      {
        "path": "locales/pl.json5"
      }
    ]
  },
  {
    "code": "hu",
    "iso": "hu",
    "name": "Magyar",
    "icon": "custom/flag-hun",
    "files": [
      {
        "path": "locales/hu.json5"
      }
    ]
  },
  {
    "code": "de",
    "iso": "de",
    "name": "Deutsch",
    "icon": "custom/flag-de",
    "files": [
      {
        "path": "locales/de.json5"
      }
    ]
  },
  {
    "code": "es",
    "iso": "es",
    "name": "Español",
    "icon": "custom/flag-es",
    "files": [
      {
        "path": "locales/es.json5"
      }
    ]
  },
  {
    "code": "fr",
    "iso": "fr",
    "name": "Français",
    "icon": "custom/flag-fr",
    "files": [
      {
        "path": "locales/fr.json5"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
